import { useReactiveVar } from '@apollo/client';
import { Animation, CircularProgress, SmallButton } from 'components';
import { useElementSize } from 'hooks';
import {
  JobOrderQueryType,
  LanguageLevelEnum,
  MatchingScoreWeightingStrategyEnum,
} from 'interfaces';
import { FC, useRef } from 'react';
import AnimateHeight from 'react-animate-height';
import { profileVar } from 'reactive-vars';
import { mergeClasses } from 'utils';
import { EnvironmentConfig } from '../../../../environment-config';
import { useYearsOfExperience } from '../../../../hooks/useYearsOfExperience';
import { JobOrderScoreCategoryEnum } from '../../../../interfaces/jobOrderScoreCategories';
import { SkillsLabel } from './JobOrderMatchingLabels';

interface JobOrdersMatchingDetailsProps {
  jobOrder: JobOrderQueryType | null;
  onShowMatchingDetailsScoreCalculation: () => void;
  isMatchingDetailsScoreCalculationVisible: boolean;
}

const JobOrdersMatchingDetails: FC<JobOrdersMatchingDetailsProps> = ({
  jobOrder,
  onShowMatchingDetailsScoreCalculation,
  isMatchingDetailsScoreCalculationVisible,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [, wrapperHeight] = useElementSize(wrapperRef);
  const profile = useReactiveVar(profileVar);

  const { yearsOfExperienceInRole, requiredYearsOfExperienceInRoleFormatted, t } =
    useYearsOfExperience({ jobOrder });

  const {
    rateRangeFrom,
    rateRangeTo,
    workMode,
    maxAvailability,
    score: {
      finalScore,
      mustHaveSkillsScore,
      niceToHaveSkillsScore,
      englishLevelScore,
      salaryScore,
      workModeScore,
      availabilityScore,
      weightingStrategy,
      roleScore,
    },
  } = jobOrder ?? { score: {} };

  const expertEnglish =
    profile?.languages?.find(({ languageId }) => languageId === EnvironmentConfig.englishLanguageId)
      ?.level ?? LanguageLevelEnum.None;

  const determineMatchingSubheader = () => {
    if (finalScore) {
      const isMeetingJobOrderRequirements = finalScore >= EnvironmentConfig.minimalScoreToApply;
      const isBelowJobOrderRequirements = finalScore < 79 && finalScore >= 50;
      if (isMeetingJobOrderRequirements) {
        return t('forms:matchingValuation:matchingSubheader.fulfilled');
      }
      if (isBelowJobOrderRequirements) {
        return t('forms:matchingValuation:matchingSubheader.incomplete');
      }
      return t('forms:matchingValuation:matchingSubheader.insufficient');
    }
  };

  return (
    <AnimateHeight
      duration={300}
      height={isMatchingDetailsScoreCalculationVisible ? wrapperHeight + 32 : 'auto'}
    >
      <div
        ref={wrapperRef}
        className={mergeClasses(
          'mt-5 flex flex-col items-center rounded-2xl border border-gray-200 px-4 pt-6 md:mt-8 md:items-start md:p-6',
          isMatchingDetailsScoreCalculationVisible ? 'pb-6' : 'pb-4',
        )}
      >
        <div className="relative w-full flex-col lg:flex">
          <div className="flex w-full items-end justify-end absolute">
            <SmallButton
              className="right-0 !text-sm font-medium text-primary-500 md:!text-base lg:top-0"
              label={
                isMatchingDetailsScoreCalculationVisible
                  ? t('forms:placeholders.fold')
                  : t('forms:placeholders.expand')
              }
              onClick={onShowMatchingDetailsScoreCalculation}
            />
          </div>
          <div className="flex items-center">
            <CircularProgress
              className="mr-4 hidden h-[65px] w-[65px] !min-w-[65px] self-start md:mb-0 lg:block lg:h-32 lg:w-32 lg:!min-w-[128px]"
              textClassName="text-black text-[14px] lg:text-[28px]"
              value={finalScore ?? 0}
            />

            <div className="w-full">
              <p className="hidden text-base font-bold leading-[21px] lg:block lg:pb-1 lg:text-xl max-w-[75%]">
                {t('forms:matchingValuation.matchingHeader', {
                  value: finalScore,
                })}
              </p>
              <div className="flex items-center gap-3 self-start pb-2 lg:hidden">
                <CircularProgress
                  className="mr-4 h-[65px] w-[65px] !min-w-[65px] self-start md:mb-0 lg:h-32 lg:w-32 lg:!min-w-[128px]"
                  textClassName="text-black text-[14px] lg:text-[28px]"
                  value={finalScore ?? 0}
                />
                <p className="max-w-[50%] text-[16px] font-bold leading-[21px] md:max-w-full lg:text-xl">
                  {t('forms:matchingValuation.matchingHeader', {
                    value: finalScore,
                  })}
                </p>
              </div>
              <div className="flex flex-col space-y-1">
                <p className="text-[14px] font-semibold leading-[22.5px]">
                  {determineMatchingSubheader()}
                </p>
                <p className="text-[14px] leading-[22.5px]">
                  {t('forms:matchingValuation.matchingExplanation')}
                </p>
                <p className="!mt-0 text-[14px] leading-[22.5px]">
                  {t('forms:matchingValuation.matchingExtraExplanation')}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Animation
          animationName="fade-in-down"
          className="w-full"
          isShowed={!!isMatchingDetailsScoreCalculationVisible}
        >
          <div className="my-4 w-full border-b border-b-gray-200 lg:mb-8 lg:mt-6" />
          <div className="grid gap-6 md:w-full md:grid-cols-[0.5fr,0.5fr] lg:gap-8">
            <SkillsLabel
              category={JobOrderScoreCategoryEnum.RequiredSkills}
              skillScore={mustHaveSkillsScore ?? 0}
            />
            <SkillsLabel
              category={JobOrderScoreCategoryEnum.Salary}
              expertSalary={profile?.rateDesired}
              rateRangeFrom={rateRangeFrom}
              rateRangeTo={rateRangeTo}
              skillScore={salaryScore ?? 0}
            />
            <SkillsLabel
              category={JobOrderScoreCategoryEnum.PreferredSkills}
              skillScore={niceToHaveSkillsScore ?? 0}
            />
            <SkillsLabel
              category={JobOrderScoreCategoryEnum.WorkMode}
              preferredWorkType={profile?.workType}
              requiredWorkType={workMode}
              skillScore={workModeScore ?? 0}
            />

            <SkillsLabel
              category={JobOrderScoreCategoryEnum.Languages}
              expertEnglish={expertEnglish}
              minEnglishLevel={jobOrder?.minEnglishLevel}
              skillScore={englishLevelScore ?? 0}
            />
            <SkillsLabel
              category={JobOrderScoreCategoryEnum.Availability}
              noticeLength={profile?.noticeLength}
              preferredAvailability={profile?.availability}
              requiredAvailability={maxAvailability}
              skillScore={availabilityScore ?? 0}
            />
            {weightingStrategy === MatchingScoreWeightingStrategyEnum.RolesIncludedStrategy && (
              <SkillsLabel
                category={JobOrderScoreCategoryEnum.YearsInRole}
                isJobPositionStrictlyRequired={false}
                requiredYearsOfExperienceInRoleFormatted={requiredYearsOfExperienceInRoleFormatted}
                skillScore={roleScore ?? 0}
                yearsOfExperienceInRole={yearsOfExperienceInRole}
              />
            )}
          </div>
        </Animation>
      </div>
    </AnimateHeight>
  );
};

export default JobOrdersMatchingDetails;
