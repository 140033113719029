import { TFunction } from 'i18next';
import { ExpertAvailabilityEnum, ExpertTag, LanguageLevelEnum, Shape } from 'interfaces';
import * as yup from 'yup';
import { compactByValue } from '../../utils/compact';
import { LanguagesStepValues } from './steps';
import { AvailabilityStepValues } from './steps/AvailabilityStep';
import { NameStepValues } from './steps/NameStep';
import { PreferencesStepValues } from './steps/PreferencesStep';
import { RoleStepValues } from './steps/RoleStep';
import { SalaryStepValues } from './steps/SalaryStep';
import { SkillsStepValues } from './steps/SkillsStep';
import {
  basicInformationLimits,
  MAX_FILE_SIZE,
  MAX_NAME_CHARACTERS,
  NO_SPECIAL_CHARACTERS,
} from './utils/inputLimitations';

export const nameStepSchema = (t: TFunction) =>
  yup.object().shape<Shape<NameStepValues>>({
    firstName: yup
      .string()
      .required(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.firstName').toLowerCase(),
        }) ?? '',
      )
      .max(
        MAX_NAME_CHARACTERS,
        t('forms:validationMessages.atMax', {
          fieldName: t('forms:labels.firstName').toLowerCase(),
          max: 50,
        }) ?? '',
      )
      .test('match', t('forms:validationMessages.wrongFormat') as string, function (value) {
        return NO_SPECIAL_CHARACTERS.test(value ?? '');
      }),
    lastName: yup
      .string()
      .required(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.lastName').toLowerCase(),
        }) ?? '',
      )
      .max(
        MAX_NAME_CHARACTERS,
        t('forms:validationMessages.atMax', {
          fieldName: t('forms:labels.lastName').toLowerCase(),
          max: 50,
        }) ?? '',
      )
      .test('match', t('forms:validationMessages.wrongFormat') as string, function (value) {
        return NO_SPECIAL_CHARACTERS.test(value ?? '');
      }),
    expertCv: yup
      .mixed()
      .test('fileSize', t('forms:validationMessages.fileTooBig', { max: '5MB' }) ?? '', (value) => {
        if (!value) return true;
        return value.size <= MAX_FILE_SIZE;
      }),
  });

export const roleStepSchema = (t: TFunction) =>
  yup.object().shape<Shape<RoleStepValues>>({
    jobPositionId: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.role').toLowerCase(),
      }) ?? '',
    ),
    mainTechnologyIds: yup.array().test(
      'required',
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.mainTechnologies').toLowerCase(),
      }) ?? '',
      function () {
        const { requiredMainTechnologyLength, mainTechnologyIds } = this.parent;
        if (!this.parent.requiredMainTechnologyLength) {
          return true;
        }
        return compactByValue(mainTechnologyIds, undefined).length >= requiredMainTechnologyLength;
      },
    ),
    experienceYears: yup.number().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.experienceYears').toLowerCase(),
      }) ?? '',
    ),
    seniority: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.seniority').toLowerCase(),
      }) ?? '',
    ),
  });

export const skillsStepSchema = (t: TFunction) =>
  yup.object().shape<Shape<SkillsStepValues>>({
    tags: yup.array().test(
      'atLeast3Filled',
      t('forms:validationMessages.atLeast', {
        min: 3,
        fieldName: t('forms:labels.technologies').toLowerCase(),
      }) ?? '',
      (tags?: ExpertTag[]) => {
        if (!tags) return false;
        const filledTags = tags.filter(({ weight }) => weight).length;
        return filledTags >= 3;
      },
    ),
  });

export const preferencesStepSchema = (t: TFunction) =>
  yup.object().shape<Shape<PreferencesStepValues>>({
    location: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.location').toLowerCase(),
      }) ?? '',
    ),
    workType: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.workType').toLowerCase(),
      }) ?? '',
    ),
  });

export const salaryStepSchema = (t: TFunction) =>
  yup.object().shape<Shape<SalaryStepValues>>({
    rateDesired: yup
      .number()
      .typeError(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.rateDesired').toLowerCase(),
        }) ?? '',
      )
      .required(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.rateDesired').toLowerCase(),
        }) ?? '',
      )
      .min(
        basicInformationLimits.MIN_HOURLY_RATE,
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.rateDesired').toLowerCase(),
        }) ?? '',
      )
      .max(
        basicInformationLimits.MAX_HOURLY_RATE,
        t('forms:validationMessages.maxSalary', {
          fieldName: t('forms:labels.rateDesired').toLowerCase(),
          max: basicInformationLimits.MAX_HOURLY_RATE,
        }) ?? '',
      ),
  });

export const availabilityStepSchema = (t: TFunction) =>
  yup.object().shape<Shape<AvailabilityStepValues>>({
    availability: yup.string().required(
      t('forms:validationMessages.required', {
        fieldName: t('forms:labels.availability').toLowerCase(),
      }) ?? '',
    ),
    noticeLength: yup
      .number()
      .typeError(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.availability').toLowerCase(),
        }) ?? '',
      )
      .when('availability', (availability: ExpertAvailabilityEnum, schema) => {
        if (availability === ExpertAvailabilityEnum.Days) {
          return schema
            .max(100, t('forms:validationMessages.maxDays', { max: 100 }))
            .required(
              t('forms:validationMessages.requiredDays') ?? 'Wymagane gdy dostępność to dni',
            );
        }
        if (availability === ExpertAvailabilityEnum.Months) {
          return schema
            .max(10, t('forms:validationMessages.maxMonths', { max: 10 }))
            .required(
              t('forms:validationMessages.requiredMonths') ?? 'Wymagane gdy dostępność to miesiące',
            );
        }
        return schema.nullable();
      }),
  });

export const languageStepSchema = (t: TFunction) =>
  yup.object().shape<Shape<LanguagesStepValues>>({
    languages: yup
      .array()
      .of(
        yup.object().shape({
          languageId: yup.string().required(
            t('forms:validationMessages.required', {
              fieldName: t('forms:labels.languages').toLowerCase(),
            }) ?? '',
          ),
          level: yup
            .mixed()
            .nullable()
            .test(
              'is-level-missing',
              t('forms:validationMessages.EnglishSkillMissing', {
                fieldName: t('forms:labels.language').toLowerCase(),
              }) ?? '',
              function (value) {
                return value !== LanguageLevelEnum.None;
              },
            ),
        }),
      )
      .required(
        t('forms:validationMessages.required', {
          fieldName: t('forms:labels.languages').toLowerCase(),
        }) ?? '',
      ),
  });
