import dayjs from 'dayjs';
import 'dayjs/locale/pl';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.locale('pl');
dayjs.extend(relativeTime);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(duration);
dayjs.extend(updateLocale);

dayjs.updateLocale('pl', {
  relativeTime: {
    future: 'za %s',
    past: '%s temu',
    s: 'kilka sekund',
    m: 'minuta',
    mm: '%d minut',
    h: 'godzina',
    hh: '%d godzin',
    d: 'dzień',
    dd: '%d dni',
    M: '1 miesiąc',
    MM: (months: number) => {
      if ([2, 3, 4].includes(months)) {
        return `${months} miesiące`;
      }
      return `${months} miesięcy`;
    },
    y: 'rok',
    yy: (years: number) => {
      if (years === 1) {
        return 'rok';
      }
      if ([2, 3, 4].includes(years)) return `${years} lata`;
      return `${years} lat`;
    },
  },
});
