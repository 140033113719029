import { Button, CircularProgress, IconButton, SmallButton } from 'components';
import dayjs from 'dayjs';
import { useAsyncCallback, useCopyToClipboard } from 'hooks';
import {
  CopyToClipboardIcon,
  HeartIcon,
  HeartSolidIcon,
  IconType,
  LanguageIcon,
  LevelsIcon,
  LocationIcon,
  OfficeIcon,
} from 'icons';
import { JobOrderApplicationProcessStatusEnum, JobOrderQueryType } from 'interfaces';
import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { findElementByKey, mergeClasses } from 'utils';
import ConnectisLogo from '../../../../assets/images/connectis-logo.svg';
import { EnvironmentConfig } from '../../../../environment-config';
import { useOnboardingDataQuery } from '../../../../hooks/api/onboardingData/onboardingData.generated';
import { determineRequiredLanguageLevel } from '../../../Modals/OfferDetailsModal/subcomponents/JobOrderDetailsHeader';
import { getStatusBadge, recruitmentInterviewStatuses } from './utils';

interface JobOrderListItemProps extends JobOrderQueryType {
  className?: string;
  onScheduleMeetingClick?: (jobOrderId: string) => void;
  onFavouriteClick?: (jobOrderId: string) => void;
  onDetailsClick?: (jobOrderId: string) => void;
  isFavouriteButtonDisabled?: boolean;
  isFavouriteButtonLoading?: boolean;
}

export const Detail = ({
  value,
  href,
  isLink = false,
  icon: Icon,
  iconClassName = '!min-w-4 min-h-4 max-w-4 max-h-4',
  className,
}: {
  value: string;
  icon?: IconType;
  isLink?: boolean;
  href?: string;
  iconClassName?: string;
  className?: string;
}) => {
  const handleClick = (e: MouseEvent<HTMLSpanElement>) => {
    if (!isLink) return;
    e.stopPropagation();
    e.preventDefault();
    window.open(href, '_blank');
  };

  return (
    <p className="text-sm flex gap-1.5 transition-all duration-300 ease-in-out">
      {Icon && <Icon className={mergeClasses(iconClassName)} />}
      <span
        className={mergeClasses(
          'font-medium h-8',
          className,
          isLink ? 'hover:underline cursor-pointer' : '',
        )}
        onClick={handleClick}
      >
        {value}
      </span>
    </p>
  );
};

export const JobOrderListItem: FC<JobOrderListItemProps> = ({
  className = '',
  rateRangeTo,
  rateRangeFrom,
  jobOrderId,
  role,
  locationPreference,
  onScheduleMeetingClick,
  onFavouriteClick,
  onDetailsClick,
  isSaved,
  score,
  application,
  workMode,
  seniority,
  leadingTechnology,
  isFavouriteButtonDisabled = false,
  isFavouriteButtonLoading = false,
  minEnglishLevel,
  assignmentDate,
}) => {
  const { t, i18n } = useTranslation();
  const { copyToClipboard } = useCopyToClipboard();

  const { selectedMeetingDate, processStatus, meetingUrl, status } = application ?? {};
  const isApplied = status;
  const isFooterShowed = !isApplied;
  const { finalScore } = score ?? {};
  const isMeetingDisabled = EnvironmentConfig.minimalScoreToApply > finalScore;
  const isMeetingStatus =
    processStatus === JobOrderApplicationProcessStatusEnum.ScheduledForCq ||
    (processStatus && recruitmentInterviewStatuses.includes(processStatus));

  const { data: onboardingData } = useOnboardingDataQuery();
  const { tags } = onboardingData ?? { tags: [] };
  const leadingTechName = leadingTechnology
    ? findElementByKey(tags, 'id', leadingTechnology)?.name
    : '';

  const roundedRateRangeFrom = rateRangeFrom ? Math.round(rateRangeFrom) : 0;
  const roundedRateRangeTo = rateRangeTo ? Math.round(rateRangeTo) : 0;

  const [handleFavouriteOffer, { loading: favouriteJobOrderLoading }] =
    useAsyncCallback(onFavouriteClick);

  const dateString = application
    ? `${t('offers:offerListItem.applied')}: ${dayjs(application.createdAt).format('DD.MM.YYYY')}`
    : `${t('offers:offerListItem.added')}: ${dayjs(assignmentDate)
        .locale(i18n.language)
        .fromNow()}`;

  return (
    <li
      className={mergeClasses(
        'offer-list-item rounded-lg border border-gray-200 bg-white p-6 transition-all flex justify-between flex-col min-[1330px]:flex-row',
        onDetailsClick ? 'hover:bg-gray-50 hover:bg-opacity-10 cursor-pointer' : '',
        className,
      )}
      onClick={() => onDetailsClick && onDetailsClick(jobOrderId)}
    >
      <div className="flex flex-col justify-between items-center md:items-start w-full lg:pb-4 xl:pb-0 xl:justify-end">
        <div className="grid grid-cols-[minmax(70px,70px)_1fr] items-center w-full md:w-auto gap-x-4 md:gap-x-5 md:gap-x-4 md:gap-y-4">
          <CircularProgress
            className={mergeClasses(
              '!min-w-[70px] !min-h-[70px] !w-[70px] !h-[70px] row-start-1 row-end-2 md:!row-end-5 lg:!row-end-4 mb-2 md:mb-0',
            )}
            textClassName="text-black"
            value={finalScore ?? '-'}
          />
          <div
            className={mergeClasses(
              'flex space-y-0 lg:space-x-2 flex-col-reverse lg:flex-row gap-0.5 lg:gap-2',
            )}
          >
            <p className={mergeClasses('font-semibold text-[17px] lg:text-[20px] leading-[19px]')}>
              {role} {leadingTechName}
            </p>
            {assignmentDate && (
              <p
                className={mergeClasses(
                  'text-gray-400 text-[14px] leading-[21px] whitespace-nowrap hidden lg:block',
                )}
              >
                {dateString}
              </p>
            )}
          </div>

          {assignmentDate && (
            <div
              className={mergeClasses(
                'flex gap-4 items-center lg:hidden',
                isApplied ? 'row-start-2 row-end-3' : '',
              )}
            >
              <p
                className={mergeClasses(
                  'text-gray-400 text-[14px] leading-[21px] whitespace-nowrap',
                )}
              >
                {dateString}
              </p>
              {!isApplied && getStatusBadge(t, { processStatus })}
            </div>
          )}

          <div
            className={mergeClasses(
              'w-full md:w-fit flex flex-col md:flex-row lg:items-center col-start-1 md:col-start-2 col-end-3 pb-6 md:pb-0 pt-3 md:pt-0 gap-3 lg:gap-1',
            )}
          >
            <img
              alt="logo"
              className="w-[95px] lg:w-[113px] h-[12px] lg:h-[15px]"
              src={ConnectisLogo}
            />
            <p className="self-start">
              <span className="text-[16px] leading-[20px] font-medium">
                {`${roundedRateRangeFrom}-${roundedRateRangeTo}`} PLN
              </span>
              <span className="text-gray-400">/{t('common:hourShort')}</span>
              <span className="text-gray-400"> - B2B</span>
            </p>
          </div>
          <div
            className={mergeClasses(
              'flex flex-row flex-wrap gap-y-2 grid-cols-2 grid-rows-2 md:flex col-start-1 md:col-start-2 col-end-3 md:gap-4 md:w-max col-end-3',
            )}
          >
            {locationPreference && (
              <Detail
                className="whitespace-nowrap text-ellipsis overflow-hidden mr-4 lg:mr-0 max-w-[123px]"
                icon={LocationIcon}
                iconClassName="!w-4 !h-4 text-gray-400 !min-w-4"
                value={locationPreference}
              />
            )}
            {workMode && (
              <Detail
                className="mr-4 lg:mr-0"
                icon={OfficeIcon}
                iconClassName="!w-4 !h-4 text-gray-400"
                value={t(`dictionaries:workTypes.${workMode}`)}
              />
            )}
            {seniority && (
              <Detail
                className="mr-4 lg:mr-0"
                icon={LevelsIcon}
                iconClassName="!w-4 !h-4 text-gray-400"
                value={seniority}
              />
            )}
            <Detail
              className="mr-4 lg:mr-0"
              icon={LanguageIcon}
              iconClassName="!w-4 !h-4 text-gray-400"
              value={`${t('forms:labels.english')} ${determineRequiredLanguageLevel(
                minEnglishLevel,
              )}`}
            />
          </div>
        </div>
        <span className="border-t border-t-[##F0EDED] w-full my-4 inline lg:hidden" />
      </div>

      <div className="flex flex-col gap-1 lg:gap-3 justify-start whitespace-nowrap xl:items-end">
        {isApplied && getStatusBadge(t, { processStatus })}
        {selectedMeetingDate && isMeetingStatus && isApplied && (
          <div className="xl:text-right space-y-2">
            <p className="text-sm leading-[19px] font-medium whitespace-nowrap capitalize">
              {dayjs(selectedMeetingDate).locale(i18n.language).format('dddd, DD.MM.YYYY HH:mm')}
            </p>
          </div>
        )}
        {isApplied && meetingUrl && isMeetingStatus ? (
          <div className="flex flex-row items-center gap-2 xl:justify-end">
            <a
              className="text-sm text-primary-500 hover:underline"
              href={meetingUrl}
              onClick={(e) => e.stopPropagation()}
              rel="noreferrer"
              target="_blank"
            >
              {t('offers:offerListItem.goToMeeting')}
            </a>
            <IconButton
              className="!p-1"
              icon={CopyToClipboardIcon}
              iconClassName="w-4"
              onClick={(e) => copyToClipboard(meetingUrl, e)}
            />
          </div>
        ) : null}
      </div>
      {isFooterShowed && (
        <div className="flex justify-between items-start xl:items-end flex-col relative gap-4 pl-2">
          <div className="flex items-center gap-y-3 gap-x-4 lg:gap-x-2 flex-row w-full md:w-auto">
            {onFavouriteClick && (
              <SmallButton
                className="!bg-transparent !p-0 text-[14px] lg:text-[16px] leading-[19px] lg:leading-[21px] font-medium items-center relative"
                icon={isSaved ? HeartSolidIcon : HeartIcon}
                iconClassName="text-primary-500 mr-0.5"
                isDisabled={isFavouriteButtonDisabled || favouriteJobOrderLoading}
                isLoading={isFavouriteButtonLoading || favouriteJobOrderLoading}
                label={
                  isSaved
                    ? t('offers:offerListItem.unsaveOffer')
                    : t('offers:offerListItem.saveOffer')
                }
                labelClassName="text-[14px] lg:text-[16px] leading-[19px] lg:leading-[21px] text-primary-500 gap-1 whitespace-nowrap"
                onClick={(e) => {
                  e.stopPropagation();
                  handleFavouriteOffer(jobOrderId);
                }}
              />
            )}
          </div>
          {!isMeetingDisabled && onScheduleMeetingClick && (
            <Button
              className="!px-[22.5px] w-full lg-w-auto m-0 items-center"
              isDisabled={isMeetingDisabled}
              label={t('offers:offerListItem.scheduleMeeting')}
              labelClassName="text-[14px] md:text-[16px] leading-[19px] whitespace-nowrap"
              onClick={(e) => {
                e.stopPropagation();
                onScheduleMeetingClick(jobOrderId);
              }}
            />
          )}
          {isMeetingDisabled && (
            <div className="text-[14px] leading-[21px] rounded-md bg-gray-200/50 py-2 px-4">
              {t('offers:offerListItem.notEnoughScore')}
            </div>
          )}
        </div>
      )}
    </li>
  );
};
